import React from "react"
import Shared from "../components/Shared"
import { SearchContextProvider } from "../components/SearchContextProvider"
import Footer from "../components/Footer"
import "../components/layout.css"

function SharedPage() {

  return (
    <SearchContextProvider>
      <Shared />
      <Footer />
    </SearchContextProvider>
  )
}

export default SharedPage
